import DateFnsUtils from '@date-io/date-fns';
import {
    Button, Card, CardContent,
    CardHeader,
    Grid,
    MenuItem,
    TextField,
    Typography,
    makeStyles,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core';
import { AdapterDayjs }                     from '@mui/x-date-pickers/AdapterDayjs';    
import { LocalizationProvider }             from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker }                   from '@mui/x-date-pickers/DateTimePicker';
import { DemoContainer }                    from '@mui/x-date-pickers/internals/demo';
import { format }                           from 'date-fns';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Divider, Stack } from '@mui/material';
import React, { useContext, useEffect, useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import EligoBackdrop from '../../../common/EligoBackdrop';
import EligoSnackBar from '../../../common/EligoSnackBar';
import { PollCategory, Roles, Status, SurveyCategory, TypeOfPollCategory, UserRole,
    TypeOfPoll } from '../../../common/GenericCodes';
import { UserContext } from '../../../common/context/UserContext';
import * as Validators from '../../../common/form-validators.js';
import { 
    AxiosEvotingInterceptor,
    LookUpAxiosEvotingInterceptor
} from '../../../config/axios.interceptor';
import PollAdvanceSettings from '../poll-settings/PollAdvanceSettings';
import PollSettings from '../poll-settings/PollSettings';
import dayjs                                from 'dayjs';

const TYPE_POLL = 'POLL';
const TYPE_SURVEY = 'SURVEY';

const useStyles = makeStyles((theme) => ({
    btn: {
        marginLeft: theme.spacing(1),
        float: 'right'
    },
    card: {
        boxShadow: 'rgb(0 0 0 / 6%) 0px 3px 3px -2px, rgb(0 0 0 / 4%) 0px 3px 4px 0px, rgb(0 0 0 / 4%) 0px 1px 8px 0px !important',
        borderRadius: '8px'
    },
    disableColor: {
        '& .MuiInputBase-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important'
        },
        '& .MuiInput-input.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important'
        },
        '& .MuiFormLabel-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.7) !important'
        },
        '& .MuiIconButton-root.Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.5) !important'
        },
        '& .MuiChip-root.Mui-disabled': {
            opacity: '0.9 !important'
        }
    }
}));

const EditPollRound = props => {
    const { hasSystemAdmineOrSupportRole } = props;
    const systemTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const { t } = useTranslation();
    const classes = useStyles();
    const context = useContext(UserContext);
    const isSystemAdminSupport = (context.userSession.session.roles?.includes(UserRole.Role_Admin) || context.userSession.session.roles?.includes(UserRole.Role_Support));
    const [errors, setErrors] = React.useState({});
    const [touched, setTouched] = React.useState({});
    const [isEdit, setIsEdit] = React.useState(false);
    const [loading, setLoading] = useState(false);
    const [pollStatus, setPollStatus] = useState('');
    const [refresh, setRefresh] = useState(true);
    const [message, setMessage] = React.useState({
        showMsg: false,
        message: null,
        severity: null
    });
    const [pollRoundDetail, setPollRoundDetail] = useState({
        poll_id: props.pollDetails.id,
        reference_number: props.pollDetails.referenceNumber,
        title: props.pollDetails.title,
        description: props.pollDetails.description,
        show_results_to_voters: props.pollDetails.showResultsToVoters,
        weighted_votes: props.pollDetails.weightedVotes,
        geo_tagging: props.pollDetails.geoTagging,
        geo_fencing: props.pollDetails.geoFencing,
        questionShuffle: props.pollDetails.questionShuffle,
        anonymous_voting: props.pollDetails.anonymousVoting,
        website_redirect: props.pollDetails.websiteRedirect,
        disclose_voter_in_result: props.pollDetails.discloseVoterInResult,
        status: props.pollDetails.status,
        start_date_time: props.pollDetails.startTime ? dayjs.utc(props.pollDetails.startTime) : null,
        end_date_time: props.pollDetails.endTime ? dayjs.utc(props.pollDetails.endTime) : null,
        result_post_on_poll_close: props.pollDetails.resultPostOnPollClose,
        start_manually: props.pollDetails.startManually,
        alert_time: props.pollDetails.alertTime ? dayjs.utc(props.pollDetails.alertTime) : null,
        sent_start_alert: props.pollDetails.sentStartAlert,
        sent_end_alert: props.pollDetails.sentEndAlert,
        voteWithSignUp: props.pollDetails.voteWithSignUp,
        announce_result_automatically: props.pollDetails.announceResultAutomatically,
        allowVoteChange: props.pollDetails.allowVoteChange,
        displayOrganizerName: props.pollDetails.displayOrganizerName,
        scrutinizerHasToOpen: props.pollDetails.scrutinizerHasToPpen,
        verifyWithOtp: props.pollDetails.verifyWithOtp,
        poll_type: props.pollDetails.pollType,
        conductForPersonId: props.pollDetails.conductFor ? props.pollDetails.conductFor.personId : '',
        conductForPersonName: props.pollDetails?.conductFor ? props.pollDetails.conductFor.name : '',
        realTimeResult: props.pollDetails.realTimeResult,
        pollCategory: props.pollDetails.pollCategory,
        secreteBallot: props.pollDetails.secreteBallot,
        zoneId: props.pollDetails.zoneId,
        zoneDisplayName: props.pollDetails.zoneDisplayName,
        previousState: ''
    })

    const [clients, setClients] = useState([]);
    const [timeZones, setTimeZones] = useState([]);

    const getTimeZones = () => {
        LookUpAxiosEvotingInterceptor.get('timezone').then(response => {
            setTimeZones(response);
        }).catch(error => {
            console.log('error', error);
        })
    }

    const getClientNames = () => {
        AxiosEvotingInterceptor.get('client/list?page=1&size=100&asc=false').then(response => {
            setClients(response.client);
        }).catch(error => {
            console.log('error', error);
        })
    }

    const validate = {
        title: title => Validators.requiredValidation(t('Title'), title),
        description: description => null,
        start_date_time: start_date_time =>
            Validators.requiredValidation(t('Begin_Date_&_Time'), start_date_time == null ? '' : start_date_time) ||
            Validators.dateValidation(t('Begin_Date_&_Time'), start_date_time),
        end_date_time: end_date_time =>
            Validators.requiredValidation(t('Close_Date_&_Time'), end_date_time == null ? '' : end_date_time) ||
            Validators.dateValidation(t('Close_Date_&_Time'), end_date_time),
        alert_time: alert_time =>
            Validators.requiredValidation(t('Alert_Date_&_Time'), alert_time == null ? '' : alert_time) ||
            Validators.dateValidation(t('Alert_Date_&_Time'), alert_time),
        pollCategory: pollCategory => Validators.requiredValidation(t('Category'), pollCategory)
    };

    const getPoll = (pollId) => {
        setLoading(true);
        AxiosEvotingInterceptor.get(`poll/${pollId}`).then(poll => {
            setPollRoundDetail({
                poll_id: poll.id,
                reference_number: poll.referenceNumber,
                title: poll.title,
                description: poll.description,
                show_results_to_voters: poll.showResultsToVoters,
                weighted_votes: poll.weightedVotes,
                geo_tagging: poll.geoTagging,
                geo_fencing: poll.geoFencing,
                questionShuffle: poll.questionShuffle,
                anonymous_voting: poll.anonymousVoting,
                website_redirect: poll.websiteRedirect,
                disclose_voter_in_result: poll.discloseVoterInResult,
                status: poll.status,
                start_date_time: poll.startTime ? dayjs.utc(poll.startTime) : null,
                end_date_time: dayjs.utc(poll.endTime),
                result_post_on_poll_close: poll.resultPostOnPollClose,
                start_manually: poll.startManually,
                alert_time: dayjs.utc(poll.alertTime),
                sent_start_alert: poll.sentStartAlert,
                sent_end_alert: poll.sentEndAlert,
                voteWithSignUp: poll.voteWithSignUp,
                announce_result_automatically: poll.announceResultAutomatically,
                allowVoteChange: poll.allowVoteChange,
                displayOrganizerName: poll.displayOrganizerName,
                scrutinizerHasToOpen: poll.scrutinizerHasToOpen,
                verifyWithOtp: poll.verifyWithOtp,
                poll_type: poll.pollType,
                conductForPersonId: poll.conductFor ? poll.conductFor.personId : '',
                conductForPersonName: (poll.conductFor !== null && poll.conductFor !== undefined) ? (poll.conductFor.name + (poll.conductFor.restOfName === null ? '' : ` ${poll.conductFor.restOfName}`)) : '',
                pollCategory: poll.pollCategory,
                realTimeResult: poll.realTimeResult,
                previousState: '',
                noOfVoterNotVoted: poll.noOfVoterNotVoted,
                noOfVoterVoted: poll.noOfVoterVoted,
                secreteBallot: poll.secreteBallot,
                zoneId: poll.zoneId,
                zoneDisplayName: poll.zoneDisplayName
            })
            props.setPollInfo(poll);
            props.setNoOfVotedNotVoted({
                voted: poll.noOfVoterVoted,
                notVoted: poll.noOfVoterNotVoted
            })
            setLoading(false);
            setRefresh(false);
            setTimeout(() => {
                setRefresh(true);
            }, 200);
        }).catch(error => {
            setLoading(false);
            console.log('error', error);
        })
    }

    useEffect(() => {
        getPoll(props.pollDetails.id);
        getClientNames();
        getTimeZones();
    }, [])

    const updatePollROundService = (payload) => {
        setLoading(true);
        setMessage({ showMsg: false, message: '', severity: '' });
        AxiosEvotingInterceptor.put(`poll/${pollRoundDetail.poll_id}`, payload).then(response => {
            props.setPollTitle(pollRoundDetail.title)
            setMessage({ showMsg: true, message: t('Poll_Updated_Successfully'), severity: 'success' });
            getPoll(pollRoundDetail.poll_id);
            setIsEdit(false);
            setLoading(false);
        }).catch(error => {
            setLoading(false)
            if (error.message) {
                setMessage({ showMsg: true, message: error.message, severity: 'error' });
            }
        })

    }

    const onJsonInputChange = (event) => {
        setPollRoundDetail(prevState => ({
            ...prevState,
            [event.target.id]: { en: event.target.value, fr: '' }
        }));
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const onInputChange = event => {
        setPollRoundDetail(prevState => ({
            ...prevState,
            [event.target.id]: event.target.value
        }));
        setTouched(prevState => ({
            ...prevState,
            [event.target.id]: true,
        }))
    }

    const handleDateChange = (fieldName, selectedDate) => {
        if (selectedDate != null && selectedDate !== "") {
            setPollRoundDetail(prevState => ({
                ...prevState,
                [fieldName]: selectedDate
            }));
        }
        setTouched(prevState => ({
            ...prevState,
            [fieldName]: true,
        }))
    };

    const onInputChangeByName = (fieldName, value) => {
        setPollRoundDetail(prevState => ({
            ...prevState,
            [fieldName]: value
        }));
        setTouched(prevState => ({
            ...prevState,
            [fieldName]: true,
        }))
    }


    const handleDataBoolean = (event, stateVariable) => {
        let id = null;
        if (event.target.id != "") {
            id = event.target.id;
        } else if (event.target.parentElement.id != "") {
            id = event.target.parentElement.id;
        } else if (event.target.parentElement.parentElement.id != "") {
            id = event.target.parentElement.parentElement.id;
        }
        if (id != null) {
            setPollRoundDetail(prevState => ({
                ...prevState,
                [id]: !prevState[id]
            }))
            setTouched(prevState => ({
                ...prevState,
                [id]: true,
            }))
        }
    }

    const handleBlur = (name, value) => {
        setErrors(prevState => ({
            ...prevState,
            [name]: '',
        }))
        const error = validate[name](value);
        setErrors(prevState => ({
            ...prevState,
            ...(error && { [name]: touched[name] && error }),
        }))
    }

    const updatePollRound = (event) => {
        event.preventDefault();
        validateForm().then(data => {
            if (Object.values(data.errors).length === 0) {
                let payload = {
                    referenceNumber: pollRoundDetail.reference_number,
                    title: pollRoundDetail.title,
                    description: pollRoundDetail.description,
                    showResultsToVoters: pollRoundDetail.show_results_to_voters,
                    weightedVotes: pollRoundDetail.weighted_votes,
                    geoTagging: pollRoundDetail.geo_tagging,
                    geoFencing: pollRoundDetail.geo_fencing,
                    questionShuffle: (TypeOfPollCategory.IBC === pollRoundDetail.pollCategory) ? false : pollRoundDetail.questionShuffle,
                    anonymousVoting: pollRoundDetail.anonymous_voting,
                    websiteRedirect: pollRoundDetail.website_redirect,
                    discloseVoterInResult: pollRoundDetail.disclose_voter_in_result,
                    status: pollRoundDetail.status,
                    startTime: pollRoundDetail.start_date_time,
                    endTime: pollRoundDetail.end_date_time,
                    resultPostOnPollClose: pollRoundDetail.result_post_on_poll_close,
                    startManually: pollRoundDetail.start_manually,
                    alertTime: pollRoundDetail.alert_time,
                    alerted: pollRoundDetail.alerted,
                    voteWithSignUp: pollRoundDetail.voteWithSignUp,
                    announceResultAutomatically: pollRoundDetail.announce_result_automatically,
                    allowVoteChange: pollRoundDetail.allowVoteChange,
                    displayOrganizerName: pollRoundDetail.displayOrganizerName,
                    scrutinizerHasToOpen: pollRoundDetail.scrutinizerHasToOpen,
                    verifyWithOtp: pollRoundDetail.verifyWithOtp,
                    pollType: pollRoundDetail.poll_type,
                    conductFor: {
                        personId: pollRoundDetail.conductForPersonId
                    },
                    pollCategory: pollRoundDetail.pollCategory,
                    realTimeResult: pollRoundDetail.realTimeResult,
                    secreteBallot: pollRoundDetail.secreteBallot,
                    zoneId: pollRoundDetail.zoneId,
                    zoneDisplayName: pollRoundDetail.zoneDisplayName
                }
                updatePollROundService(payload);
            }
        })
    }

    const editPollRound = () => {
        setPollRoundDetail(prevState => ({
            ...prevState,
            previousState: {
                poll_id: pollRoundDetail.poll_id,
                reference_number: pollRoundDetail.reference_number,
                title: pollRoundDetail.title,
                description: pollRoundDetail.description,
                show_results_to_voters: pollRoundDetail.show_results_to_voters,
                weighted_votes: pollRoundDetail.weighted_votes,
                geo_tagging: pollRoundDetail.geo_tagging,
                geo_fencing: pollRoundDetail.geo_fencing,
                questionShuffle: pollRoundDetail.questionShuffle,
                anonymous_voting: pollRoundDetail.anonymous_voting,
                website_redirect: pollRoundDetail.website_redirect,
                disclose_voter_in_result: pollRoundDetail.disclose_voter_in_result,
                status: pollRoundDetail.status,
                start_date_time: pollRoundDetail.start_date_time,
                end_date_time: pollRoundDetail.end_date_time,
                result_post_on_poll_close: pollRoundDetail.result_post_on_poll_close,
                start_manually: pollRoundDetail.start_manually,
                alert_time: pollRoundDetail.alert_time,
                sent_start_alert: pollRoundDetail.sent_start_alert,
                sent_end_alert: pollRoundDetail.sent_end_alert,
                alerted: pollRoundDetail.alerted,
                voteWithSignUp: pollRoundDetail.voteWithSignUp,
                announce_result_automatically: pollRoundDetail.announce_result_automatically,
                allowVoteChange: pollRoundDetail.allowVoteChange,
                displayOrganizerName: pollRoundDetail.displayOrganizerName,
                scrutinizerHasToOpen: pollRoundDetail.scrutinizerHasToOpen,
                verifyWithOtp: pollRoundDetail.verifyWithOtp,
                poll_type: pollRoundDetail.poll_type,
                conductForPersonId: pollRoundDetail.conductForPersonId,
                conductForPersonName: pollRoundDetail.conductForPersonName,
                pollCategory: pollRoundDetail.pollCategory,
                realTimeResult: pollRoundDetail.realTimeResult,
                secreteBallot: pollRoundDetail.secreteBallot,
                zoneId: pollRoundDetail.zoneId,
                zoneDisplayName: pollRoundDetail.zoneDisplayName
            }
        }))
        setIsEdit(true);
    }

    const cancelEditPollRound = () => {
        setPollRoundDetail(prevState => ({
            ...prevState,
            poll_id: pollRoundDetail.previousState.poll_id,
            reference_number: pollRoundDetail.previousState.reference_number,
            title: pollRoundDetail.previousState.title,
            description: pollRoundDetail.previousState.description,
            show_results_to_voters: pollRoundDetail.previousState.show_results_to_voters,
            weighted_votes: pollRoundDetail.previousState.weighted_votes,
            geo_tagging: pollRoundDetail.previousState.geo_tagging,
            geo_fencing: pollRoundDetail.previousState.geo_fencing,
            questionShuffle: pollRoundDetail.previousState.questionShuffle,
            anonymous_voting: pollRoundDetail.previousState.anonymous_voting,
            website_redirect: pollRoundDetail.previousState.website_redirect,
            disclose_voter_in_result: pollRoundDetail.previousState.disclose_voter_in_result,
            status: pollRoundDetail.status,
            start_date_time: pollRoundDetail.previousState.start_date_time,
            end_date_time: pollRoundDetail.previousState.end_date_time,
            result_post_on_poll_close: pollRoundDetail.previousState.result_post_on_poll_close,
            start_manually: pollRoundDetail.previousState.start_manually,
            alert_time: pollRoundDetail.previousState.alert_time,
            alerted: pollRoundDetail.previousState.alerted,
            sent_start_alert: pollRoundDetail.previousState.sent_start_alert,
            sent_end_alert: pollRoundDetail.previousState.sent_end_alert,
            voteWithSignUp: pollRoundDetail.previousState.voteWithSignUp,
            announce_result_automatically: pollRoundDetail.previousState.announce_result_automatically,
            allowVoteChange: pollRoundDetail.previousState.allowVoteChange,
            displayOrganizerName: pollRoundDetail.previousState.displayOrganizerName,
            scrutinizerHasToOpen: pollRoundDetail.previousState.scrutinizerHasToOpen,
            verifyWithOtp: pollRoundDetail.previousState.verifyWithOtp,
            poll_type: pollRoundDetail.previousState.poll_type,
            conductForPersonId: pollRoundDetail.previousState.conductForPersonId,
            conductForPersonName: pollRoundDetail.previousState.conductForPersonName,
            pollCategory: pollRoundDetail.previousState.pollCategory,
            realTimeResult: pollRoundDetail.previousState.realTimeResult,
            secreteBallot: pollRoundDetail.previousState.secreteBallot,
            zoneId: pollRoundDetail.previousState.zoneId,
            zoneDisplayName: pollRoundDetail.previousState.zoneDisplayName
        }))
        setRefresh(false);
        setTimeout(() => {
            setRefresh(true);
        }, 200);
        setErrors({})
        setTouched({})
        setIsEdit(false);
    }

    const validateForm = async () => {
        setErrors({})
        setTouched({})
        let formErrors = { errors: {}, touched: {} };
        Object.entries(pollRoundDetail).map(([key]) => {
            if (key === 'title' || key === 'start_date_time' || key === 'end_date_time' || key === 'description' || key === 'alert_time' ||
                    key === 'pollCategory') {
                const newError = validate[key](pollRoundDetail[key]);
                const newTouched = { [key]: true };
                return formErrors = {
                    errors: {
                        ...formErrors.errors,
                        ...(newError && { [key]: newError }),
                    },
                    touched: {
                        ...formErrors.touched,
                        ...newTouched
                    }
                }
            }
        })
        setErrors(formErrors.errors);
        setTouched(formErrors.touched);
        return formErrors;
    }

    useEffect(() => {
        if (pollRoundDetail.status === Status.ready) {
            if (new Date().getTime() > new Date(pollRoundDetail.end_date_time).getTime()) {
                setPollStatus(Status.closed);
            } else if (new Date().getTime() > new Date(pollRoundDetail.start_date_time).getTime()) {
                setPollStatus(Status.open);
            } else {
                setPollStatus(Status.upcoming);
            }
        } else {
            setPollStatus(pollRoundDetail.status)
        }
    }, [])

    useEffect(() => {
        setPollRoundDetail(prevState => ({
            ...prevState,
            alert_time: dayjs.utc(new Date(pollRoundDetail.start_date_time - (10 * 60000)))
        }));
    }, [pollRoundDetail.start_date_time])

    // useEffect(() => {
    //     if (pollRoundDetail.allowVoteChange === true) {
    //         setPollRoundDetail(prevState => ({
    //             ...prevState,
    //             realTimeResult: false
    //         }))
    //     }
    // }, [pollRoundDetail.allowVoteChange])

    return (
        <Stack spacing={1} paddingX={1} paddingY={2}>
            <Grid item xs={12}>
                {(isSystemAdminSupport || (props.roles.includes(Roles.organizer)) && (pollStatus === Status.setup || pollStatus === Status.upcoming || pollStatus === Status.publish_request ||
                        pollStatus === Status.open)) && !isEdit
                    && <Button id="edit-poll-info" type="submit" size="small" variant="contained"
                        color="primary" className={classes.btn} onClick={editPollRound}>
                        {t('Edit')}
                    </Button>
                    }
                {isEdit && <Button id="update-poll-info" type="submit" size="small" variant="contained"
                    color="primary" className={classes.btn} onClick={updatePollRound}>
                    {t('Save')}
                </Button>}
                {isEdit && <Button id='cancel-poll-info' type="submit" size="small" variant="contained" className={classes.btn}
                    onClick={cancelEditPollRound}>
                    {t('Cancel')}
                </Button>}
            </Grid>
            <Stack direction='row' spacing={1}>
                <Stack width={{ xs: '15%' }}>
                    <Card className={classes.card}>
                        <CardContent>
                            <TextField select={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)} label={t("Category")} value={pollRoundDetail.pollCategory}
                                    onChange={(event) => onInputChangeByName("pollCategory", event.target.value)}
                                    error={touched['pollCategory'] && errors['pollCategory']}
                                    helperText={touched['pollCategory'] && errors.pollCategory} fullWidth
                                    className={classes.disableColor}
                                    // onBlur={() => handleBlur('poll_category', pollRoundDetail.poll_category)}
                                    required={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                    disabled={(!isEdit || pollStatus == Status.open) && (!hasSystemAdmineOrSupportRole || !isEdit)}
                                    InputProps={{ id: 'pollCategory', disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open }}
                                    InputLabelProps={{
                                        style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                            (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                    }}>
                                {pollRoundDetail.poll_type === TYPE_POLL && PollCategory.map((type, index) => (
                                    <MenuItem key={index} value={type.type}>
                                        {type.type}
                                    </MenuItem>
                                ))}
                                {pollRoundDetail.poll_type === TYPE_SURVEY && SurveyCategory.map((type, index) => (
                                    <MenuItem key={index} value={type.type}>
                                        {type.type}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </CardContent>
                    </Card>
                </Stack>
                <Stack width={{ xs: '65%' }}>
                    <Card className={classes.card}>
                        <CardContent>
                            <TextField id="title" label={pollRoundDetail.poll_type === TypeOfPoll.POLL ? t("Title_Of_Poll") : t('Title_Of_Survey')}
                                value={pollRoundDetail.title} onChange={onInputChange}
                                error={touched['title'] && errors['title']}
                                helperText={touched['title'] && errors.title}
                                className={classes.disableColor}
                                onBlur={() => handleBlur('title', pollRoundDetail.title)}
                                fullWidth required={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                disabled={(!isEdit || pollStatus == Status.open) && (!hasSystemAdmineOrSupportRole || !isEdit)}
                                InputProps={{
                                    disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open,
                                    multiline: !isEdit || pollStatus == Status.open,
                                }}
                                InputLabelProps={{
                                    style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                        (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                }}
                            />
                        </CardContent>
                    </Card>
                </Stack>
                <Stack width={{ xs: '15%' }}>
                    <Card className={classes.card}>
                        <CardContent>
                            <TextField id="reference_number" label={t("Reference_Number")}
                                variant="standard"
                                value={((!isEdit || pollStatus == Status.open) && (!hasSystemAdmineOrSupportRole || !isEdit) &&
                                    (pollRoundDetail.reference_number === null || pollRoundDetail.reference_number === '')) ? '-' : pollRoundDetail.reference_number}
                                onChange={onInputChange}
                                error={touched['reference_number'] && errors['reference_number']}
                                helperText={touched['reference_number'] && errors.reference_number}
                                // onBlur={() => handleBlur('reference_number', pollRoundDetail.reference_number)}
                                fullWidth
                                disabled={(!isEdit || pollStatus == Status.open) && (!hasSystemAdmineOrSupportRole || !isEdit)}
                                className={classes.disableColor}
                                InputProps={{ disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open }}
                                InputLabelProps={{
                                    shrink: true,
                                    style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                        (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                }}
                            />
                        </CardContent>
                    </Card>
                </Stack>
                <Stack width={{ xs: '25%' }}>
                    <Card className={classes.card}>
                        <CardContent>
                            {(isEdit && pollStatus !== Status.open) && <TextField select={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                    label={t("Client_Name")} value={pollRoundDetail.conductForPersonId}
                                    onChange={(event) => onInputChangeByName("conductForPersonId", event.target.value)}
                                    error={touched['conductForPersonId'] && errors['conductForPersonId']}
                                    helperText={touched['conductForPersonId'] && errors.pollType} fullWidth
                                    // onBlur={() => handleBlur('conductForPersonId', pollRoundDetail.conductForPersonId)} fullWidth
                                    // required={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                    InputProps={{ id: 'conductForPersonId', disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open }}
                                    InputLabelProps={{
                                        style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                            (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                    }}>
                                {clients.map((client, index) => (
                                    <MenuItem key={index} value={client.personId}>
                                        {client.name + (client.restOfName === null ? '' : ` ${client.restOfName}`)} {client.preferredEmail ? ( `( ${client.preferredEmail} )` ) : null}
                                    </MenuItem>
                                ))}
                                {pollRoundDetail.conductForPersonId !== '' && <MenuItem key={0} value=''>
                                    <Typography color='primary'><em>{t('Clear')}</em></Typography>
                                </MenuItem>}
                            </TextField>}
                            {(!isEdit || pollStatus === Status.open) && <TextField
                                label={t("Client_Name")} value={pollRoundDetail.conductForPersonName ? pollRoundDetail.conductForPersonName : ''}
                                disabled={(!isEdit || pollStatus == Status.open) && (!hasSystemAdmineOrSupportRole || !isEdit)}
                                className={classes.disableColor}
                                InputProps={{ id: 'conductForPersonName', disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open }}
                                InputLabelProps={{
                                    style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                        (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                }}>
                            </TextField>}
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
            <Stack direction='row' spacing={1}>
                {/* <Stack width={{ xs: '20%' }}>
                    <Card className={classes.card}>
                        <CardContent>
                            <TextField select={(pollRoundDetail.status !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                    label={t("Client_Name")} value={pollRoundDetail.conductForPersonId}
                                    onChange={(event) => onInputChangeByName("conductForPersonId", event.target.value)}
                                    multiline rows={3}
                                    error={touched['conductForPersonId'] && errors['conductForPersonId']}
                                    helperText={touched['conductForPersonId'] && errors.pollType} fullWidth
                                    // onBlur={() => handleBlur('conductForPersonId', pollRoundDetail.conductForPersonId)} fullWidth
                                    required={(pollRoundDetail.status !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                    InputProps={{ id: 'conductForPersonId', disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollRoundDetail.status == Status.open }}
                                    InputLabelProps={{
                                        style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                            (!isEdit || pollRoundDetail.status == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                    }}>
                                {clients.map((client, index) => (
                                    <MenuItem key={index} value={client.personId}>
                                        {client.name} {client.preferredEmail ? ( `( ${client.preferredEmail} )` ) : null}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </CardContent>
                    </Card>
                </Stack> */}
                <Stack width={{ xs: '100%' }}>
                    <Card className={classes.card}>
                        <CardContent>
                            <TextField id="description" label={pollRoundDetail.poll_type === TypeOfPoll.POLL ? t("Description_Of_Poll") : t("Description_Of_Survey")}
                                multiline rows={3} variant="standard" value={pollRoundDetail.description} onChange={onInputChange}
                                error={touched['description'] && errors['description']}
                                helperText={touched['description'] && errors.description}
                                onBlur={() => handleBlur('description', pollRoundDetail.description)}
                                fullWidth
                                // required={pollRoundDetail.status !== Status.open && isEdit}
                                disabled={(!isEdit || pollStatus == Status.open) && (!hasSystemAdmineOrSupportRole || !isEdit)}
                                className={classes.disableColor}
                                InputProps={{ disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open }}
                                InputLabelProps={{
                                    style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                        (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                }}
                            />
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
            <Stack>
                <Card className={classes.card}>
                    <CardContent>
                        <Stack direction='row' spacing={2} sx={{width: '100%'}}>
                            <Stack direction='column'>
                                {(isEdit && pollStatus !== Status.open) && <TextField select={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)} 
                                        style={{paddingTop: '8px'}}
                                        label={t("Time_Zone")} value={pollRoundDetail.zoneId}
                                        onChange={(event) => onInputChangeByName('zoneId', event.target.value)}
                                        error={touched['zoneId'] && errors['zoneId']}
                                        helperText={touched['zoneId'] && errors.zoneId}
                                        fullWidth
                                        inputProps={{
                                            id: 'zoneId'
                                        }}
                                        className={classes.disableColor}
                                        InputProps={{ disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open }}
                                        InputLabelProps={{
                                            style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                                (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                        }}
                                        disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollStatus == Status.open)}>
                                    {timeZones.map((timeZone, index) => (
                                        <MenuItem key={timeZone.timezoneId} value={timeZone.javaZoneId}>
                                            {timeZone.name}
                                        </MenuItem>
                                    ))}
                                </TextField>}
                                {(!isEdit || pollStatus === Status.open) && <TextField  style={{paddingTop: '8px'}}
                                    label={t("Time_Zone")} value={pollRoundDetail.zoneDisplayName ? pollRoundDetail.zoneDisplayName : ''}
                                    disabled={(!isEdit || pollStatus == Status.open) && (!hasSystemAdmineOrSupportRole || !isEdit)}
                                    className={classes.disableColor}
                                    InputProps={{ id: 'zoneDisplayName', disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open }}
                                    InputLabelProps={{
                                        style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                            (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                    }}>
                                </TextField>}
                                {/* {systemTimeZone !== pollRoundDetail.zoneId && <Typography variant="caption" display="block" gutterBottom
                                        sx={{marginTop: '4px'}}>
                                    <span style={{color: 'gray'}}>System Time Zone : </span><em>{systemTimeZone}</em>
                                </Typography>} */}
                            </Stack>
                            
                            <Divider sx={{ borderWidth: '1px' }} flexItem />
                            <Stack direction='column'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width: '100%', paddingTop: '0px', overflow: 'visible'}}>
                                    <DemoContainer components={['DateTimePicker']} sx={{overflow: 'visible'}}>
                                        <DateTimePicker 
                                            sx={{paddingTop: '0px !important'}}
                                            label={t("Begin_Date_&_Time")}
                                            fullWidth
                                            variant="inline"
                                            format={process.env.REACT_APP_DATE_FORMAT_TO_DATE_SELECTOR}
                                            id="start_date_time"
                                            value={pollRoundDetail.start_date_time}
                                            minDateTime={hasSystemAdmineOrSupportRole ?  dayjs.utc(new Date('1970-01-01Z00:00:00:001')) : dayjs.utc(new Date())}
                                            maxDateTime={hasSystemAdmineOrSupportRole ? dayjs.utc(new Date('9999-01-01Z00:00:00:001')) : pollRoundDetail.end_date_time}
                                            onChange={(event) => handleDateChange('start_date_time', event)}
                                            onAccept={(event) => handleBlur('start_date_time', event)}
                                            onBlur={(event) => handleBlur('start_date_time', event.target.value)}
                                            timezone={pollRoundDetail.zoneId}
                                            disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollStatus == Status.open)}
                                            className={classes.disableColor}
                                            slotProps={{ textField: { 
                                                helperText: touched['start_date_time'] && errors.start_date_time,
                                                error: touched['start_date_time'] && errors['start_date_time'],
                                                InputProps: {
                                                    disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open,
                                                },
                                                InputLabelProps: {
                                                    style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                                        (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                                },
                                                style: {paddingTop: '0px !important'},
                                                variant: 'standard', 
                                                required: (pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit),
                                                disabled: (!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollStatus == Status.open),
                                                className: classes.disableColor,
                                                fullWidth: true
                                            },
                                            className: classes.disableColor }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {/* {systemTimeZone !== pollRoundDetail.zoneId && <Typography variant="caption" display="block" gutterBottom>
                                    <span style={{color: 'gray'}}>As per System Time Zone : </span>
                                    <em>{pollRoundDetail.start_date_time ? format(new Date(pollRoundDetail.start_date_time), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : null}</em>
                                </Typography>} */}
                            </Stack>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    variant="inline"
                                    format="dd/MM/yyyy hh:mm a"
                                    id="start_date_time"
                                    label={t("Begin_Date_&_Time")}
                                    className={classes.disableColor}
                                    value={pollRoundDetail.start_date_time}
                                    helperText={touched['start_date_time'] && errors.start_date_time}
                                    error={touched['start_date_time'] && errors['start_date_time']}
                                    minDate={hasSystemAdmineOrSupportRole ?  new Date('1970-01-01Z00:00:00:001') : new Date()}
                                    maxDate={hasSystemAdmineOrSupportRole ? new Date('9999-01-01Z00:00:00:001') : pollRoundDetail.end_date_time}
                                    onChange={(event) => handleDateChange('start_date_time', event)}
                                    onAccept={(event) => handleBlur('start_date_time', event)}
                                    onBlur={(event) => handleBlur('start_date_time', event.target.value)}
                                    required={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                    disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollStatus == Status.open)}
                                    InputProps={{
                                        disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open
                                    }}
                                    InputLabelProps={{
                                        style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } :
                                            (!isEdit || pollStatus == Status.open) ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                    }}
                                />
                            </MuiPickersUtilsProvider> */}
                            <Divider sx={{ borderWidth: '1px' }} flexItem />
                            <Stack direction='column'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width: '100%'}}>
                                    <DemoContainer components={['DateTimePicker']} sx={{overflow: 'visible'}}>
                                        <DateTimePicker 
                                            label={t("Close_Date_&_Time")}
                                            fullWidth
                                            variant="inline"
                                            format={process.env.REACT_APP_DATE_FORMAT_TO_DATE_SELECTOR}
                                            id="end_date_times"
                                            value={pollRoundDetail.end_date_time}
                                            
                                            minDateTime={ !(hasSystemAdmineOrSupportRole) ? pollRoundDetail.start_date_time : dayjs.utc(new Date('1970-01-01Z00:00:00:001'))}
                                            onChange={(event) => handleDateChange('end_date_time', event)}
                                            onAccept={(event) => handleBlur('end_date_time', event)}
                                            onBlur={(event) => handleBlur('end_date_time', event.target.value)}
                                            timezone={pollRoundDetail.zoneId}
                                            disabled={!isEdit}
                                            slotProps={{ textField: {
                                                helperText: touched['end_date_time'] && errors.end_date_time,
                                                error: touched['end_date_time'] && errors['end_date_time'], 
                                                variant: 'standard', 
                                                required: (isEdit),
                                                disabled: !isEdit,
                                                className: classes.disableColor,
                                                InputProps: { disableUnderline: !isEdit },
                                                InputLabelProps: {
                                                    style: !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                                },
                                                fullWidth: true
                                            } }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {/* {systemTimeZone !== pollRoundDetail.zoneId && <Typography variant="caption" display="block" gutterBottom>
                                    <span style={{color: 'gray'}}>As per System Time Zone : </span>
                                    <em>{pollRoundDetail.end_date_time ? format(new Date(pollRoundDetail.end_date_time), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : null}</em>
                                </Typography>} */}
                            </Stack>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    variant="inline"
                                    format="dd/MM/yyyy hh:mm a"
                                    id="end_date_times"
                                    label={t("Close_Date_&_Time")}
                                    className={classes.disableColor}
                                    value={pollRoundDetail.end_date_time}
                                    helperText={touched['end_date_time'] && errors.end_date_time}
                                    error={touched['end_date_time'] && errors['end_date_time']}
                                    minDate={ !(hasSystemAdmineOrSupportRole) && pollRoundDetail.start_date_time}
                                    // maxDate={pollDetails.end_date_time}
                                    onChange={(event) => handleDateChange('end_date_time', event)}
                                    onAccept={(event) => handleBlur('end_date_time', event)}
                                    onBlur={(event) => handleBlur('end_date_time', event.target.value)}
                                    required={isEdit}
                                    InputProps={{ disableUnderline: !isEdit }}
                                    disabled={!isEdit}
                                    InputLabelProps={{
                                        style: !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                    }}
                                />
                            </MuiPickersUtilsProvider> */}
                            <Divider sx={{ borderWidth: '1px' }} flexItem />
                            <Stack direction='column'>
                                <LocalizationProvider dateAdapter={AdapterDayjs} sx={{width: '100%'}}>
                                    <DemoContainer components={['DateTimePicker']} sx={{overflow: 'visible'}}>
                                        <DateTimePicker 
                                            label={t("Alert_Date_&_Time")}
                                            fullWidth
                                            variant="inline"
                                            format={process.env.REACT_APP_DATE_FORMAT_TO_DATE_SELECTOR}
                                            id="alert_time"
                                            value={pollRoundDetail.alert_time}
                                            minDateTime={hasSystemAdmineOrSupportRole ?  dayjs.utc(new Date('1970-01-01Z00:00:00:001')) : dayjs.utc(new Date())}
                                            maxDateTime={ hasSystemAdmineOrSupportRole ?  dayjs.utc(new Date('9999-01-01Z00:00:00:001')) : pollRoundDetail.start_date_time}
                                            onChange={(event) => handleDateChange('alert_time', event)}
                                            onAccept={(event) => handleBlur('alert_time', event)}
                                            onBlur={(event) => handleBlur('alert_time', event.target.value)}
                                            timezone={pollRoundDetail.zoneId}
                                            disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollStatus == Status.open)}
                                            slotProps={{ textField: { 
                                                helperText: touched['alert_time'] && errors.alert_time,
                                                error: touched['alert_time'] && errors['alert_time'],
                                                variant: 'standard', 
                                                required: (pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit),
                                                disabled: (!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollStatus == Status.open),
                                                className: classes.disableColor,
                                                InputProps: {
                                                    disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open || (pollRoundDetail.sent_start_alert != null && pollRoundDetail.sent_start_alert != ''),
                                                    style: { color: 'rgba(0, 0, 0, 0.87)' }
                                                },
                                                InputLabelProps: {
                                                    style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } : !isEdit || pollStatus == Status.open || (pollRoundDetail.sent_start_alert != null && pollRoundDetail.sent_start_alert != '') ?
                                                        { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                                },
                                                fullWidth: true
                                            } }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                                {/* {systemTimeZone !== pollRoundDetail.zoneId && <Typography variant="caption" display="block" gutterBottom>
                                    <span style={{color: 'gray'}}>As per System Time Zone : </span>
                                    <em>{pollRoundDetail.alert_time ? format(new Date(pollRoundDetail.alert_time), process.env.REACT_APP_DATE_FORMAT_TO_DISPLAY) : null}</em>
                                </Typography>} */}
                            </Stack>
                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDateTimePicker
                                    fullWidth
                                    variant="inline"
                                    format="dd/MM/yyyy hh:mm a"
                                    id="alert_time"
                                    label={t("Alert_Date_&_Time")}
                                    className={classes.disableColor}
                                    value={pollRoundDetail.alert_time}
                                    helperText={touched['alert_time'] && errors.alert_time}
                                    error={touched['alert_time'] && errors['alert_time']}
                                    minDate={hasSystemAdmineOrSupportRole ?  new Date('1970-01-01Z00:00:00:001') : new Date()}
                                    maxDate={ hasSystemAdmineOrSupportRole ?  new Date('9999-01-01Z00:00:00:001') : pollRoundDetail.start_date_time}
                                    onChange={(event) => handleDateChange('alert_time', event)}
                                    onAccept={(event) => handleBlur('alert_time', event)}
                                    onBlur={(event) => handleBlur('alert_time', event.target.value)}
                                    // disabled={!isEdit || pollRoundDetail.status == Status.open}
                                    required={(pollStatus !== Status.open && isEdit) || (hasSystemAdmineOrSupportRole && isEdit)}
                                    disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollStatus == Status.open)}
                                    InputProps={{
                                        disableUnderline: (hasSystemAdmineOrSupportRole && isEdit) ? false : !isEdit || pollStatus == Status.open || (pollRoundDetail.sent_start_alert != null && pollRoundDetail.sent_start_alert != ''),
                                        style: { color: 'rgba(0, 0, 0, 0.87)' }
                                    }}
                                    InputLabelProps={{
                                        style: hasSystemAdmineOrSupportRole ? !isEdit ? { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' } : !isEdit || pollStatus == Status.open || (pollRoundDetail.sent_start_alert != null && pollRoundDetail.sent_start_alert != '') ?
                                            { color: 'rgba(0, 0, 0, 0.87) !important' } : { color: '#2a83ad', fontWeight: '600' }
                                    }}
                                // required
                                />
                            </MuiPickersUtilsProvider> */}
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>
            {/* <Stack>
                <Card className={classes.card}>
                    <CardContent>
                        <Stack direction='row' spacing={8}>
                            <div>
                                <Chip
                                    id="vote_with_signUp"
                                    label={t("Allow_Voter_to_Vote_With_SignUp")}
                                    color={pollRoundDetail.vote_with_signUp ? "primary" : "default"}
                                    variant="outlined"
                                    className={classes.disableColor}
                                    clickable={pollRoundDetail.vote_with_signUp}
                                    onClick={handleDataBoolean}
                                    disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollRoundDetail.status == Status.open)}
                                    icon={pollRoundDetail.vote_with_signUp ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                />
                                <FieldHelperText helperText={t('Allow_Voter_to_Vote_With_SignUp_Helper_Text')} />
                            </div>
                            <div>
                                <Chip
                                    id="allow_vote_change"
                                    label={t("Allow_Recast")}
                                    color={pollRoundDetail.allow_vote_change ? "primary" : "default"}
                                    variant="outlined"
                                    clickable={pollRoundDetail.allow_vote_change}
                                    onClick={handleDataBoolean}
                                    disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollRoundDetail.status == Status.open)}
                                    icon={pollRoundDetail.allow_vote_change ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                />
                                <FieldHelperText helperText={t('Allow_Recast_Helper_Text')} />
                            </div>
                            <div>
                                <Chip
                                    id="display_organizer_name"
                                    label={t("Show_organizer_details")}
                                    color={pollRoundDetail.display_organizer_name ? "primary" : "default"}
                                    variant="outlined"
                                    clickable={pollRoundDetail.display_organizer_name}
                                    onClick={handleDataBoolean}
                                    disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollRoundDetail.status == Status.open)}
                                    icon={pollRoundDetail.display_organizer_name ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                />
                                <FieldHelperText helperText={t('Display_Organizer_Details_Helper_Text')} />
                            </div>&nbsp;&nbsp;
                            <div>
                                <Chip
                                    id="scrutinizer_has_to_open"
                                    label={t("Only_Scrutinizer_Should_Open_Result")}
                                    color={pollRoundDetail.scrutinizer_has_to_open ? "primary" : "default"}
                                    variant="outlined"
                                    clickable={pollRoundDetail.scrutinizer_has_to_open}
                                    onClick={handleDataBoolean}
                                    disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollRoundDetail.status == Status.open)}
                                    icon={pollRoundDetail.scrutinizer_has_to_open ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                />
                                <FieldHelperText helperText={t('Scrutinizer_Has_To_Open_Helper_Text')} />
                            </div>
                        </Stack>
                        <br />
                        <Stack direction='row' spacing={12}>
                             <div>
                                <Chip
                                    id="verify_with_otp"
                                    label={t("Should_Verify_With_OTP")}
                                    color={pollRoundDetail.verify_with_otp ? "primary" : "default"}
                                    variant="outlined"
                                    className={classes.disableColor}
                                    clickable={pollRoundDetail.verify_with_otp}
                                    onClick={handleDataBoolean}
                                    disabled={(!hasSystemAdmineOrSupportRole || !isEdit) && (!isEdit || pollRoundDetail.status == Status.open)}
                                    icon={pollRoundDetail.verify_with_otp ? <CheckBox /> : <CheckBoxOutlineBlank />}
                                />
                                <FieldHelperText helperText={t('Verify_With_OTP_Helper_Text')} />
                            </div>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack> */}
            <Stack direction='row' spacing={1}>
                <Stack width={{ xs: '100%' }}>
                    <Card className={classes.card}>
                        <CardHeader style={{padding: '12px 0px 0px 20px'}} subheader={t('Settings')}/>
                        <CardContent>
                            {refresh && <PollSettings pollDetail={pollRoundDetail} handleDataBoolean={handleDataBoolean} disabled={!isEdit || pollStatus == Status.open && !(isEdit && hasSystemAdmineOrSupportRole)}/>}
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
            <Stack direction='row' spacing={1}>
                <Stack width={{ xs: '100%' }}>
                    <Card className={classes.card}>
                        <CardHeader style={{padding: '12px 0px 0px 20px'}} subheader={t('Advance_Settings')}/>
                        <CardContent>
                            <Grid container spacing={2} style={{padding: '4px'}}>
                                {refresh && <PollAdvanceSettings pollDetail={pollRoundDetail} handleDataBoolean={handleDataBoolean}
                                    disabled={!isEdit || pollStatus == Status.open && !(isEdit && hasSystemAdmineOrSupportRole)}
                                    isBeforePublish={Status.setup.toLowerCase() === pollRoundDetail.status.toLowerCase() || hasSystemAdmineOrSupportRole}
                                    pollType={pollRoundDetail.poll_type} />}
                            </Grid>
                        </CardContent>
                    </Card>
                </Stack>
            </Stack>
            <br></br>
            {/* </Grid> */}
            {message.showMsg && <EligoSnackBar show={message.showMsg} message={message.message}
                severity={message.severity} />}
            {loading && <EligoBackdrop show={loading} />}
        </Stack>
    )
}

export default EditPollRound;

